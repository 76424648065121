<template>
  <b-popover
    :custom-class="`navigation_popover ${customPosition}`"
    :triggers="triggers"
    :target="target"
    :placement="placement"
    :offset="offset"
    :show.sync="show"
    boundary="viewport"
    @show="onShow"
    @hidden="onHidden"
  >
    <div
      v-if="isProfilePopover"
      class="profile"
    >
      <div class="picture">
        <img
          v-if="user.photo"
          :src="user.photo"
          alt="User photo"
        />
        <AvatarPlaceholder
          v-else
          size="50px"
          :text="picturePlaceholder"
        />
      </div>
      <div class="user">
        <h3>{{ user.first_name }} {{ user.last_name }}</h3>
      </div>
    </div>
    <div>
      <header v-if="title">{{ title }}</header>
      <div
        v-for="(item, index) in items"
        :key="`menu-item-${index}`"
        :class="!item && !isMaterials ? 'removed-item' : ''"
      >
        <button
          v-if="item"
          @click="handleItemClick(item)"
        >
          <div v-if="item.icon">
            <component
              :is="item.icon"
              :class="['icon', isSafariIOS ? 'ios-custom-icon' : '']"
            />
          </div>
          {{
            item.title.includes('New')
              ? `${$t(item.title)}  ${
                  levelIcons ? $t(levelIcons.find((e) => e.level == level).title) : ''
                }`
              : $t(item.title)
          }}
        </button>
      </div>
    </div>
    <div v-if="hasLanguage">
      <header>{{ secondTitle }}</header>
      <LanguageSelector
        variant="full"
        @closePopover="(e) => closePopover(e)"
      />
    </div>
  </b-popover>
</template>

<script>
  import HomeIcon from '@core/assets/icons/home-icon.svg';
  import { BPopover } from 'bootstrap-vue';
  import LanguageSelector from './LanguageSelector';
  import AvatarPlaceholder from '@/@core/components/avatar-placeholder/index.vue';
  export default {
    name: 'PopOver',
    components: { HomeIcon, BPopover, LanguageSelector, AvatarPlaceholder },
    props: {
      title: { type: String, required: false },
      items: {
        type: Array || Null,
        required: true
      },
      secondTitle: { type: String, required: false },
      hasLanguage: {
        type: Boolean,
        default: false
      },
      user: {
        type: Object
      },
      levelIcons: {
        type: Array
      },
      level: {
        type: Number
      },
      isProfilePopover: {
        type: Boolean,
        default: false
      },
      isMaterials: {
        type: Boolean,
        default: false
      },
      placement: {
        type: String,
        default: 'right'
      },
      triggers: {
        type: String,
        default: 'focus'
      },
      target: {
        type: String,
        required: true
      },
      offset: {
        type: Number,
        default: 0
      },
      customPosition: {
        type: String,
        default: null
      }
    },
    data: () => ({ show: false }),
    methods: {
      onShow() {
        this.$emit('onShow');
      },
      onHidden() {
        this.$emit('onHidden');
      },
      closePopover(e) {
        e ? (this.show = e) : (this.show = false);
      },
      handleItemClick(item) {
        item.click(item);
        this.$emit('click');
        this.closePopover();
      }
    },
    computed: {
      isSafariIOS() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      },
      picturePlaceholder() {
        const firstLetter = (str) => String(str)?.[0]?.toUpperCase() ?? '';
        return `${firstLetter(this.user.first_name)}${firstLetter(this.user.last_name)}`;
      }
    }
  };
</script>

<style lang="scss">
  .navigation_popover {
    .arrow {
      display: none !important;
    }

    .popover-body {
      padding: 0 !important;
      width: inherit !important;
      border: none !important;
    }

    &:has(.popover-body .removed-item) {
      width: 111px !important;
    }
    width: 180px !important;

    &.navbar {
      top: -3px !important;
      left: 40px !important;
      width: 232px !important;
    }

    &.sidebar {
      top: -20px !important;
    }

    &.action-button {
      width: 111px;
    }

    &.--double-modal-openned {
      left: -50px !important;
    }

    &.draggable-component {
      width: 223px !important;
    }

    &.--max-content {
      width: max-content !important;
    }

    &.w-100 {
      width: 100% !important;
    }

    &.function-filter {
      max-height: 300px;
      max-width: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-track {
        background: #fff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #cfc4be;
        border-radius: 9px;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .icon {
    width: 16px;
    height: 100%;
    margin-top: 1px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popover {
    display: flex;
    width: 100%;
    padding: 8px 0px;
    border-radius: 6px;
    border: none;
    background: #fff;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 8px 30px 0px rgba(58, 46, 38, 0.1);

    .profile {
      display: flex;
      align-items: center;
      padding: 6.5px;
      border-bottom: 1px solid #eee;

      .picture {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 9px;

        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 100%;
        }
      }

      &:hover {
        background-color: #ffede1 !important;
      }

      .user {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        align-items: flex-end;
        width: 130px;

        h3 {
          margin: 0;
          padding: 0;
          text-align: left;
        }

        h3 {
          color: #4c4541;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    header {
      padding: 12px 21px;
      font-size: 14px;
      font-weight: 600;
      color: #4c4541;
    }

    button {
      outline: 0;
      background: transparent;
      border: 0;
      cursor: pointer;

      width: 100%;
      padding: 12px 21px;

      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 9px;

      font-size: 14px;
      font-weight: 400;
      color: #998f8a;

      user-select: none;

      svg {
        fill: #998f8a;
      }

      &:hover {
        background-color: #ffede2;
        color: #974900;

        svg {
          fill: #974900 !important;
        }
      }

      &:active {
        background-color: #ffdbc4;

        svg {
          fill: #974900 !important;
        }
      }

      &.selected {
        background: #974900;
      }
    }
  }

  .ios-custom-icon {
    margin-top: -3px !important;
  }
</style>
